<div class="inner-header"> 
 <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="inner-header-text">
          <h2 class="animated fadeInUp text-center">About us</h2>
          <p class="text-center"><a href="#">Go to home</a> / About us</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding about-sec-1">
  <div class="container">
    <div class="row justify-content-center">
      <!-- <div class="col-md-12">
        <div class="about-2-text">
          <h3 class="wow fadeInUp">25 Years Of Experience In <br/><span>Web Development</span></h3>
        </div>
      </div> -->
      <div class="col-md-12">
        <div class="about-2-text">
          <p class="abtext">Dataspace Solutions LLP is formed by group of industry experts from various
            domains with more than a decade of experience to develop eminent products and
            back them with world -class support.
            Our business model focuses on having long-term strategic relations with
            our clients by providing a combination of high quality, cost effective and on time
            delivery of solutions. In each client project, we work beyond the norms of a
            conventional client relationship by investing more time to understand customer
            current and future business needs.</p>
            
            <p class="abtext">Inspired and motivated by Agile Manifesto core values, we are an extensive
            Agile methodologies &amp; practices implementing technology organization, which in
            terms delivers faster and stable solutions to advance your business. At Dataspace,
            we leverage our extensive experience to imagine, develop, manage and deliver
            consistent quality solutions as per your business need.</p>
            
            <p class="abtext">Our vision is to help organizations in successful and qualitative
            representation of their businesses on web by truly understanding their needs
            followed by customized solutions for them. Our main aim is to deliver highly
            quality product and to maintain long lasting relationship with our clients.</p>
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="progress">
          <div class="progress-bar progress-bg" style="width:40%"> HTML 40%</div>
        </div>
        <div class="progress">
          <div class="progress-bar progress-bg" style="width:70%">PHP 70%</div>
        </div>
        <div class="progress">
          <div class="progress-bar progress-bg" style="width:90%">WordPress 90%</div>
        </div>
        <div class="progress">
          <div class="progress-bar progress-bg" style="width:30%">CSS 30%</div>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!-- <section class="section-padding about-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="text-cont">
          <h4 class="wow fadeInUp">About us</h4>
          <h2 class="wow fadeInUp">Trusted Business Partner</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
          <div class="row">
            <div class="col-md-4">
              <div class="item-cont">
                <div class="icon-cont"><i class="icofont-gift"></i></div>
                <div class="text-cont">
                  <h3><span class='numscroller' data-min='1' data-max='220' data-delay='5' data-increment='10'>220</span> +</h3>
                  <h6>Item Sold</h6>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item-cont">
                <div class="icon-cont"><i class="icofont-users-social"></i></div>
                <div class="text-cont">
                  <h3><span class='numscroller' data-min='1' data-max='180' data-delay='5' data-increment='10'>180</span> +</h3>
                  <h6>Online Clients</h6>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item-cont">
                <div class="icon-cont"><i class="icofont-ui-text-chat"></i></div>
                <div class="text-cont">
                  <h3><span class='numscroller' data-min='1' data-max='225' data-delay='5' data-increment='10'>225</span> +</h3>
                  <h6>Client Feedback</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</section> -->
<!-- <section class="section-padding about-sec-3">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="about-2-text">
          <h3 class="wow fadeInUp">Our Real Commitment Reaches <br/>
            Beyond Gas & Oil Company.</h3>
          <h5 class="wow fadeInUp">Our Combined Experience</h5>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about-2-text">
          <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or 
            randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything </p>
          <p>Ambarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. </p>
        </div>
      </div>
    </div>
  </div>
</section> -->