<div class="inner-header">
  
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="inner-header-text">
          <h2 class="animated fadeInUp text-center">Blog Details</h2>
          <p class="text-center"><a href="#">Index</a> / Blog Details</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section id="blog-details" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-8">
        <div class="post"> <img class="img-fluid" src="assets/images/blog-details.png" alt="First item">
          <h3><a href="#">Best Agency for Website Design and Development</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of 
              classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin 
              professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, 
              consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical 
              literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 
              1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 
              45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The 
              first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
              Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also 
              reproduced in their exact original form, accompanied by English versions from the 1914 translation 
              by H. Rackham.</p>
          </div>
          <div class="social-share"><span>Share us on:</span>
            <button type="button" class="btn btn-circle btn-facebook"><i class="icofont-facebook"></i></button>
            <button type="button" class="btn btn-circle btn-twitter"><i class="icofont-twitter"></i></button>
            <button type="button" class="btn btn-circle btn-linkedin"><i class="icofont-linkedin"></i></button>
          </div>
        </div>
        <div class="comment-form-cont">
          <h4>Comment Form</h4>
          <form action="process.php" method="post" name="ContactForm" id="ContactForm">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="form-group">
                  <input type="text" class="form-control" name="fname" placeholder="First Name *">
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <div class="form-group">
                  <input type="text" class="form-control" name="lname" placeholder="Last Name *">
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <div class="form-group">
                  <input type="tel" class="form-control" name="phone" placeholder="Phone *">
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <div class="form-group">
                  <input type="email" class="form-control" name="email" placeholder="Email *">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <textarea rows="5" class="form-control" name="comment" placeholder="Your Message *" style="height:150px"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button type="submit" class="btn btn-md btn-secondary wow tada">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="sidebar">
          <div class="sidebar-item">
            <div class="sidebar-title">
              <h5>Recent Categories</h5>
            </div>
            <div class="sidebar-body">
              <ul>
                <li><a href="#">Business</a></li>
                <li><a href="#">Web Development</a></li>
                <li><a href="#">Online Marketing</a></li>
                <li><a href="#">App Development</a></li>
              </ul>
            </div>
          </div>
          <div class="sidebar-item">
            <div class="sidebar-title">
              <h5>Recent Posts</h5>
            </div>
            <div class="sidebar-body">
              <ul>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">How to improve your sale</a></span>
                </li>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb-1.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">How to improve your website rank</a></span>
                </li>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb-2.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">Get super flexibility</a></span>
                </li>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb-3.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">What is Lorem Ipsum</a></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>