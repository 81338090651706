<div class="inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="inner-header-text">
          <h2 class="animated fadeInUp text-center">Contact</h2>
          <p class="text-center"><a href="#">Go to home</a> / Contact</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding contact">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="title">
        	<h6 class="text-center">Contact us</h6>
            <h2 class="text-center">Send us a mail</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form action="process.php" method="post" name="ContactForm" id="ContactForm" >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" name="fname" placeholder="First Name *">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" name="lname" placeholder="Last Name *">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="tel" class="form-control" name="phone" placeholder="Phone *">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="email" class="form-control" name="email" placeholder="Email *">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <textarea rows="5" class="form-control" name="comment" placeholder="Your Message *" style="height:150px"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                
                <button type="submit" class="btn btn-secondary btn-lg wow tada">Submit</button>
              </div>
            </div>
          </form>
      </div>  
    </div>
  </div>
</section>
<div class="map-section">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5079.364788775433!2d78.35058153349419!3d17.496811137396623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb926eea56ae25%3A0xe1264262718f7b94!2sBalaji%20Towers!5e0!3m2!1sen!2sin!4v1647365543082!5m2!1sen!2sin" width="600" height="450" style="border:0; width:100%; height:500px;" allowfullscreen="" loading="lazy"></iframe></div>