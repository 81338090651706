<div class="inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="inner-header-text">
          <h2 class="animated fadeInUp text-center">Services</h2>
          <p class="text-center"><a href="#">Go to home</a> / Services</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding services-1">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="item">  <div class="img-cont mb1-5"><img class="img-fluid" src="assets/images/about-2-icon-1.png" alt="logo"></div>
          <h4>Database Migration & Consulting Services</h4>
          <!-- <p>Business Consultancy International is your gateway to a successful future in business.</p> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="item">  <div class="img-cont mb1-5"><img class="img-fluid" src="assets/images/about-2-icon-2.png" alt="logo"></div>
          <h4>Enterprise Engineering Solutions & Support</h4>
          <!-- <p>Business Consultancy International is your gateway to a successful future in business.</p> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="item">  <div class="img-cont mb1-5"><img class="img-fluid" src="assets/images/about-2-icon-3.png" alt="logo"></div>
          <h4>Custom Database Design & Development</h4>
          <!-- <p>Business Consultancy International is your gateway to a successful future in business.</p> -->
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="item">  <div class="img-cont mb1-5"><img class="img-fluid" src="assets/images/about-2-icon-4.png" alt="logo"></div>
          <h4>Resource Staffing & Consulting - C2H</h4>
          <!-- <p>Business Consultancy International is your gateway to a successful future in business.</p> -->
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-padding services-3 ssection">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="img-cont"><img class="img-fluid" src="assets/images/services-2.png" alt="logo"></div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="text-cont">
          <h6>10 Years of Effective Business</h6>
          <!-- <h3>Management & Consulting Solutions</h3> -->
          <p>At the end of the day, going forward, a new normal that has evolved from <br/>
            generation X is on the runway heading towards a streamlined solution.</p>
          <div class="row">
            <div class="col-md-6"> 
              <div class="item">
              <i class="icofont-papers"></i>
              <h4>Our Strategy</h4>
              <p> Staying ahead of the competition and abreast of consumer trends while ensuring customer satisfaction is much easier with cloud Engineering, but it still can be a 24/7/365 job. Free up your team to focus on building other aspects of your business by engaging our skilled managed IT team. Our full-scope services include efficiency audits, SLA management, and oversight of all critical elements of your business to save you time, budget, and resources.</p>
              </div>
            </div>
            <div class="col-md-6"> 
              <div class="item">
              <i class="icofont-shield-alt"></i>
              <h4>Our Model</h4>
              <p>We start from the beginning and stick with you through the entire journey of designing, developing and managing your cloud-based app.
                Our comprehensive development solutions include innovative multi- and poly-cloud strategies based on the best leading providers for your business — whether you require enterprise cloud adoption, CloudOps or any other cloud application.</p>
              <p>Our Scrum / Kanban & SAFe-based Agile Development methodology drives a dynamic, iterative development process of measuring, testing, and scaling your application for results aligned with your business objectives.
                Our culture delivers value to your Database Management and Application development through launch and beyond, including rapid, continuous delivery, ongoing innovation, and rich opportunities for ROI and business growth.</p>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="section-padding services-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="services-2-item"> <span class="step-point">01</span> <i class="icofont-paint"></i>
          <h5>Premium Design</h5>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting indstry. lpsum has been the</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="services-2-item"> <span class="step-point">02</span> <i class="icofont-code-alt"></i>
          <h5>Standard Coding</h5>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting indstry. lpsum has been the</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="services-2-item"> <span class="step-point">03</span> <i class="icofont-responsive"></i>
          <h5>Fully Responsive</h5>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting indstry. lpsum has been the</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="services-2-item"> <span class="step-point">04</span> <i class="icofont-paper"></i>
          <h5>Well Documented</h5>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting indstry. lpsum has been the</p>
        </div>
      </div>
    </div>
  </div>
</section> -->