<div class="inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="inner-header-text">
          <h2 class="animated fadeInUp text-center">Blog</h2>
          <p class="text-center"><a href="#">Index</a> / Blog</p>
        </div>
      </div>
    </div>
  </div>
</div>
<section id="blog-details" class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-8">
        <div class="post wow fadeInUp"> <img class="img-fluid" src="assets/images/post-1.jpg" alt="First item">
          <h3><a href="#">Best Agency for Website Design and Development</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
              Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also 
              reproduced in their exact original form, accompanied by English versions from the 1914 translation 
              by H. Rackham.</p>
          </div>
          <a class="readmore" href="#">Read More <i class="icofont-long-arrow-right"></i></a>
        </div>
        <div class="post wow fadeInUp"> <img class="img-fluid" src="assets/images/post-2.jpg" alt="First item">
          <h3><a href="#">Get super flexibility</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
              Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also 
              reproduced in their exact original form, accompanied by English versions from the 1914 translation 
              by H. Rackham.</p>
          </div>
          <a class="readmore" href="#">Read More <i class="icofont-long-arrow-right"></i></a>
          
        </div>
        <div class="post wow fadeInUp"> <img class="img-fluid" src="assets/images/post-3.jpg" alt="First item">
          <h3><a href="#">What is Lorem Ipsum</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
              Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also 
              reproduced in their exact original form, accompanied by English versions from the 1914 translation 
              by H. Rackham.</p>
          </div>
          <a class="readmore" href="#">Read More <i class="icofont-long-arrow-right"></i></a>
        </div>
      </div>
      <div class="col-lg-3 col-md-4">
        <div class="sidebar">
          <div class="sidebar-item">
            <div class="sidebar-title">
              <h5>Recent Categories</h5>
            </div>
            <div class="sidebar-body">
              <ul>
                <li><a href="#">Business</a></li>
                <li><a href="#">Web Development</a></li>
                <li><a href="#">Online Marketing</a></li>
                <li><a href="#">App Development</a></li>
              </ul>
            </div>
          </div>
          <div class="sidebar-item">
            <div class="sidebar-title">
              <h5>Recent Posts</h5>
            </div>
            <div class="sidebar-body">
              <ul>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">How to improve your sale</a></span>
                </li>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb-1.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">How to improve your website rank</a></span>
                </li>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb-2.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">Get super flexibility</a></span>
                </li>
                <li>
                	<span class="img-cont"><img src="assets/images/post-widget-thumb-3.png" class="img-fluid" alt="post-widget-thumb"></span>
                    <span class="text-cont"><a href="#">What is Lorem Ipsum</a></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>