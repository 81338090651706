  <header class="header-1">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="header-1-text">
          <h5>Welcome to our website</h5>
          <h2 class="animated fadeInUp">We Provide Creative Designs</h2>
          <p>It is a long established fact that a reader will be distracted by the <br/> readable content of a page when looking at its layout. </p>
          <a href="#" class="btn btn-lg btn-primary">Get a Quote </a> <a href="#" class="btn btn-lg btn-secondary">Read More </a></div>
      </div>
      <div class="col-md-4">
        <div class="header-1-img"><img class="img-fluid" src="assets/images/header-1-img.png" alt="header-1"></div>
      </div>
    </div>
  </div>
</header>
<section class="section-padding about-4">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
      	<div class="row">
      		<div class="col-md-6">
            	<div class="about-4-item">
                    <div class="img-cont"><img src="assets/images/about-4-icon-1.png" class="img-fluid" alt=""></div>
                    <div class="text-cont">
                    	<h3><span class='numscroller' data-min='1' data-max='1800' data-delay='5' data-increment='10'>1800</span> +</h3>
                        <h6>Item Sold</h6>
                    </div>
            	</div>
             </div>
            <div class="col-md-6">
            	<div class="about-4-item">
                    <div class="img-cont"><img src="assets/images/about-4-icon-2.png" class="img-fluid" alt=""></div>
                    <div class="text-cont">
                    	<h3><span class='numscroller' data-min='1' data-max='1400' data-delay='5' data-increment='10'>1400</span> +</h3>
                        <h6>Online Clients</h6>
                    </div>
            	</div>
            </div>
            <div class="col-md-6">
            	<div class="about-4-item">
                    <div class="img-cont"><img src="assets/images/about-4-icon-3.png" class="img-fluid" alt=""></div>
                    <div class="text-cont">
                    	<h3><span class='numscroller' data-min='1' data-max='2000' data-delay='5' data-increment='10'>2000</span> +</h3>
                        <h6>Client Feedback</h6>
                    </div>
            	</div>
            </div>
            <div class="col-md-6">
            	<div class="about-4-item">
                    <div class="img-cont"><img src="assets/images/about-4-icon-4.png" class="img-fluid" alt=""></div>
                    <div class="text-cont">
                    	<h3><span class='numscroller' data-min='1' data-max='1600' data-delay='5' data-increment='10'>1600</span> +</h3>
                        <h6>Expert Designers</h6>
                    </div>
            	</div>
            </div>
      	</div>
      
      </div>
      <div class="col-md-6">
      	<div class="about-4-right">
        	<h6 class="wow fadeInUp">What we do</h6>
            <h3 class="wow fadeInUp">We Provide Creative Designes</h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem </p>
			<p>Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. 
Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for </p>
			<a href="#" class="btn btn-lg btn-secondary">Get a free quote</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section-padding services-4">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6"><div class="left-img wow fadeInLeft"><img src="assets/images/services-4.png" class="img-fluid" alt=""></div></div>
      <div class="col-md-6">
      	<div class="services-4-items">
        	<div class="item">
            	<div class="img-cont"><img src="assets/images/services-4-icon-1.png" class="img-fluid" alt=""></div>
                <div class="text-cont">
                	<h5>Web Design & Development</h5>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour</p>
                </div>
            </div>
            <div class="item">
            	<div class="img-cont"><img src="assets/images/services-4-icon-2.png" class="img-fluid" alt=""></div>
                <div class="text-cont">
                	<h5>Mobile App & Development</h5>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour</p>
                </div>
            </div>
            <div class="item">
            	<div class="img-cont"><img src="assets/images/services-4-icon-3.png" class="img-fluid" alt=""></div>
                <div class="text-cont">
                	<h5>Search Engine Optimisation</h5>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>       
<section class="section-padding about-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
      	<div class="left">
        	<h6 class="wow fadeInUp">Who we are</h6>
            <h3 class="wow fadeInUp">We Provide Creative Designes</h3>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem </p>
            <ul>
                <li>Ipsum is that it has a more-or-less normal distribution </li>
                <li>Of letters, as opposed to using 'Content here, content here', </li>
                <li>Making it look like readable English. </li>
                <li>Many desktop publishing packages and web page editors now use</li>
                <li>Ipsum as their default model text, and a search for </li>
            </ul>
            <a href="#" class="btn btn-lg btn-primary">Read More </a>
        </div>
      </div>
      <div class="col-md-6">
      	<div class="right">
        	<img src="assets/images/about-5.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>
</section>      
<section class="section-padding recent-posts">
  <div class="container">
  	<div class="row justify-content-center">
      <div class="col-md-6">
        <div class="title">
        	<h6 class="text-center">Blog</h6>
            <h2 class="text-center">Our Recent Posts</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6"><div class="post"> <img class="img-fluid" src="assets/images/post-1.jpg" alt="First item">
          <div class="textcont">
          <h3><a href="#">Best agency for web design</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
          </div>
          <a class="readmore" href="#">Read More <i class="icofont-long-arrow-right"></i></a>
          </div>
        </div></div>
      <div class="col-lg-4 col-md-6"><div class="post"> <img class="img-fluid" src="assets/images/post-2.jpg" alt="First item">
         <div class="textcont">
          <h3><a href="#">Get super flexibility</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
          </div>
          <a class="readmore" href="#">Read More <i class="icofont-long-arrow-right"></i></a>
          </div>
          
        </div></div>
      <div class="col-lg-4 col-md-6"><div class="post"> <img class="img-fluid" src="assets/images/post-3.jpg" alt="First item">
          <div class="textcont">
          <h3><a href="#">What is Lorem Ipsum</a></h3>
          <h6><a href="#">12 Oct 2017</a> | <a href="#">By Admin</a> | <a href="#">5 Comments</a></h6>
          <div class="post-text">
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
          </div>
          <a class="readmore" href="#">Read More <i class="icofont-long-arrow-right"></i></a>
          </div>
        </div></div>
    </div>
  </div>
</section>    