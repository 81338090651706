<div class="header-2">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="banner-2-text">
          <h2 class="animated fadeInUp">We are <br>  Data Migration,<br> Cloud Computing
            and Resource Partnering <span> Company </span></h2>
          <!-- <p>Lorem Ipsum is simply dummy text of the printing and <br/>
            typesetting industry. Lorem Ipsum has been the industry's <br/>
            standard dummy text ever since the 1500s,</p> -->
          <!-- <a href="#" class="btn btn-lg btn-secondary">Get a Free Quote </a>  -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="header-2-img"><img class="img-fluid" src="assets/images/header-2.png" alt="header-2 "></div>
      </div>
    </div>
  </div>
</div>
<section class="section-padding about-2">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="about-2-text">
          <h6 class="wow fadeInUp">About us</h6>
          <h3 class="wow fadeInUp">Take your data migration process to the next level with our expert team</h3>
          <p>We as a Database Migration and Custom DB Services Provider helps
            companies migrate their business-critical data to the leading cloud
            platforms and on-premises environments. We help organizations to
            move data, objects, and schemas and build micro solutions for DB
            services. </p>
          <p>We specializes in custom database development, database management
            (DBMS), administration, Integrations, Version Upgrades and support of MS SQL,
            MS Access, MySQL, PostGreSQL, Oracle, Sybase. </p>
          <div class="row">
            <div class="col-md-6">
              <div class="about-2-item">
                <div class="img-cont"><img class="img-fluid" src="assets/images/about-2-icon-1.png" alt="logo"></div>
                <div class="text-cont">
                  <h5>Database Migration & Consulting Services</h5>
                  <!-- <p>It is a long established fact that a reader will be distracted by the readable </p> -->
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="about-2-item">
                <div class="img-cont"><img class="img-fluid" src="assets/images/about-2-icon-2.png" alt="logo"></div>
                <div class="text-cont">
                  <h5>Enterprise Engineering Solutions & Support</h5>
                  <!-- <p>It is a long established fact that a reader will be distracted by the readable </p> -->
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="about-2-item">
                <div class="img-cont"><img class="img-fluid" src="assets/images/about-2-icon-3.png" alt="logo"></div>
                <div class="text-cont">
                  <h5>Custom Database Design & Development</h5>
                  <!-- <p>It is a long established fact that a reader will be distracted by the readable </p> -->
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="about-2-item">
                <div class="img-cont"><img class="img-fluid" src="assets/images/about-2-icon-4.png" alt="logo"></div>
                <div class="text-cont">
                  <h5>Resource Staffing & Consulting - C2H</h5>
                  <!-- <p>It is a long established fact that a reader will be distracted by the readable </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="about-2-form">
          <h3><i class="icofont-envelope-open"></i> Request A Quote</h3>
          <div class="form-body">
            <form class="contactform" method="post" action="process.php" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" placeholder="Name" name="name" class="validate form-control" required="">
                    <span class="input-focus-effect"></span> </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="email" placeholder="Email" name="email" class="validate form-control" required="">
                    <span class="input-focus-effect"></span> </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" placeholder="Subject" name="subject" class="validate form-control" required="">
                    <span class="input-focus-effect"></span> </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input type="text" placeholder="Phone" name="phone" class="validate form-control" required="">
                    <span class="input-focus-effect"></span> </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <textarea placeholder="Your Comment" name="message" class="form-control" required=""></textarea>
                    <span class="input-focus-effect"></span> </div>
                </div>
                <div class="col-md-12">
                  <div class="send">
                    <button class="btn btn-secondary" type="submit" name="send"> Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section-padding counter csection" >
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="">
          <div class="icon-cont"><img class="img-fluid n-svg" src="assets/images/aws.png" alt="logo"></div>
          <!-- <div class="text-cont">
            <h2><span class='numscroller' data-min='1' data-max='1200' data-delay='5' data-increment='10'>1200</span>+</h2>
            <h6>Products Made</h6>
          </div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <div class="icon-cont"><img class="img-fluid n-svg" src="assets/images/sybase.png" alt="logo"></div>
          <!-- <div class="text-cont">
            <h2><span class='numscroller' data-min='1' data-max='2000' data-delay='5' data-increment='10'>2000</span>+</h2>
            <h6>Satisfied Clients</h6>
          </div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div class="">
          <div class="icon-cont"><img class="img-fluid n-svg" src="assets/images/oracle-6.svg" alt="logo"></div>
          <!-- <div class="text-cont">
            <h2><span class='numscroller' data-min='1' data-max='3000' data-delay='5' data-increment='10'>3000</span>+</h2>
            <h6>Projects Done</h6>
          </div> -->
        </div>
      </div>
      <div class="col-md-3">
        <div class="item">
          <div class="icon-cont"><img class="img-fluid" src="assets/images/unnamed.png" alt="logo" style="margin-left: auto;
            margin-right: auto;
            width: 50%;"></div>
          <!-- <div class="text-cont">
            <h2><span class='numscroller' data-min='1' data-max='1000' data-delay='5' data-increment='10'>1000</span>+</h2>
            <h6>Team Members</h6>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="section-padding team">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="title">
        	<h6 class="text-center">Team</h6>
            <h2 class="text-center">Our Team Members</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="item">
                    <div class="inner-box">
                        <div class="image-box">
                            <a href="#"><img src="assets/images/member-1.png" class="img-fluid" alt=""></a>
                        </div>
                        <div class="desc-content">
                            <h3>Alex Jhonson</h3>
                            <div class="designation">Web Developer</div>
                        </div>
                        <div class="hover-content">
                            <h3>Alex Jhonson</h3>
                            <div class="designation">Web Developer</div>
                            <div class="social">
                                <a href="#"><span class="icofont-facebook"></span></a>
                                <a href="#"><span class="icofont-twitter"></span></a>
                                <a href="#"><span class="icofont-google-plus"></span></a>
                                <a href="#"><span class="icofont-linkedin"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="item">
                    <div class="inner-box">
                        <div class="image-box">
                            <a href="#"><img src="assets/images/member-2.jpg" class="img-fluid" alt=""></a>
                        </div>
                        <div class="desc-content">
                            <h3>Eric Jhonson</h3>
                            <div class="designation">Web Developer</div>
                        </div>
                        <div class="hover-content">
                            <h3>Eric Jhonson</h3>
                            <div class="designation">Web Developer</div>
                            <div class="social">
                                <a href="#"><span class="icofont-facebook"></span></a>
                                <a href="#"><span class="icofont-twitter"></span></a>
                                <a href="#"><span class="icofont-google-plus"></span></a>
                                <a href="#"><span class="icofont-linkedin"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="item">
                    <div class="inner-box">
                        <div class="image-box">
                            <a href="#"><img src="assets/images/member-3.jpg" class="img-fluid" alt=""></a>
                        </div>
                        <div class="desc-content">
                            <h3>Emma Jhonson</h3>
                            <div class="designation">Web Developer</div>
                        </div>
                        <div class="hover-content">
                            <h3>Emma Jhonson</h3>
                            <div class="designation">Web Developer</div>
                            <div class="social">
                                <a href="#"><span class="icofont-facebook"></span></a>
                                <a href="#"><span class="icofont-twitter"></span></a>
                                <a href="#"><span class="icofont-google-plus"></span></a>
                                <a href="#"><span class="icofont-linkedin"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="item">
                    <div class="inner-box">
                        <div class="image-box">
                            <a href="#"><img src="assets/images/member-4.jpg" class="img-fluid" alt=""></a>
                        </div>
                        <div class="desc-content">
                            <h3>Sara Jhonson</h3>
                            <div class="designation">Web Developer</div>
                        </div>
                        <div class="hover-content">
                            <h3>Sara Jhonson</h3>
                            <div class="designation">Web Developer</div>
                            <div class="social">
                                <a href="#"><span class="icofont-facebook"></span></a>
                                <a href="#"><span class="icofont-twitter"></span></a>
                                <a href="#"><span class="icofont-google-plus"></span></a>
                                <a href="#"><span class="icofont-linkedin"></span></a>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
      
    </div>
  </div>
</section>      -->
<section class="section-padding testimonial">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="title">
        	<h6 class="text-center">Testimonial</h6>
            <h2 class="text-center">What our clients says about us</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
      <div class="testimonial-item owl-carousel owl-theme">
        <div class="item">
        	<blockquote  style="min-height: 63px;">Always ready with solutions - for all kinds of problems<br></blockquote>
			      <div class="client-info">
            	<div class="client-img">
                <!-- <img src="assets/images/client.png" class="rounded-circle" alt="" /> -->
              </div>
                <div class="client-text">
                	<h5>Inmind Tech</h5>
            		  <h6>Vice president</h6>
                </div>
            </div>
        </div>
        <div class="item">
        	<blockquote>A very professional and helpful organization. The work is always carried out in a timely and well
            organized fashion. Cannot recommend you highly enough.</blockquote>
			      <div class="client-info">
            	<div class="client-img">
                <!-- <img src="assets/images/client.png" class="rounded-circle" alt="" /> -->
              </div>
                <div class="client-text">
                	<h5>Sunray India</h5>
            		  <h6>C.E.O</h6>
                </div>
            </div>
        </div>
        <!-- <div class="item">
        	<blockquote>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 
2000 years old. </blockquote>
			<div class="client-info">
            	<div class="client-img"><img src="assets/images/client.png" class="rounded-circle" alt="" /></div>
                <div class="client-text">
                	<h5>Alex Jhonson</h5>
            		<h6>Coder</h6>
                </div>
            </div>
        </div> -->
      </div>  
      </div>   
    </div>
   </div>
</section>   
<!-- <section class="section-padding pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="title">
        	<h6 class="text-center">Pricing</h6>
            <h2 class="text-center">Our Affordable Price List</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
      	<div class="item">
        	<h4>STANDARD PLAN</h4>
            <h5>The Best Price</h5>
            <h3>$99 /<small>month</small></h3>
            <ul>
            	<li>30 days trial Features</li>
                <li>Synced to cloud database</li>
                <li>10 hours of support</li>
                <li>Social media integration</li>
                <li>Unlimited Features</li>
            </ul>
            <a href="#" class="btn btn-secondary">Subscribe </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
      	<div class="item">
        	<h4>PREMIUM PLAN</h4>
            <h5>The Best Price</h5>
            <h3>$199 /<small>month</small></h3>
            <ul>
            	<li>30 days trial Features</li>
                <li>Synced to cloud database</li>
                <li>10 hours of support</li>
                <li>Social media integration</li>
                <li>Unlimited Features</li>
            </ul>
            <a href="#" class="btn btn-secondary">Subscribe </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
      	<div class="item">
        	<h4>BUSINESS PLAN</h4>
            <h5>The Best Price</h5>
            <h3>$299 /<small>month</small></h3>
            <ul>
            	<li>30 days trial Features</li>
                <li>Synced to cloud database</li>
                <li>10 hours of support</li>
                <li>Social media integration</li>
                <li>Unlimited Features</li>
            </ul>
            <a href="#" class="btn btn-secondary">Subscribe </a>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
      	<div class="item">
        	<h4>EXECUTIVE PLAN</h4>
            <h5>The Best Price</h5>
            <h3>$399 /<small>month</small></h3>
            <ul>
            	<li>30 days trial Features</li>
                <li>Synced to cloud database</li>
                <li>10 hours of support</li>
                <li>Social media integration</li>
                <li>Unlimited Features</li>
            </ul>
            <a href="#" class="btn btn-secondary">Subscribe </a>
        </div>
      </div>
    </div>
  </div>
</section>          -->