<footer>
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="item">
            <a href="index.html" style="display: flex;">
              <span style="color: #008cfe;font-size: 2rem;font-weight: bold">Dataspace   </span> &nbsp;&nbsp;<span style="color: #ea5539;font-size: 2rem;font-weight: bold">Solutions</span>
          </a>
            <p>info@dataspacesolution.com</p>
            <p>+91- 9493212743</p>
          </div>
          <div class="item">
            <h5>Our Social Presence</h5>
            <ul class="social">
              <li><a href="#"><i class="icofont-facebook"></i></a></li>
              <li><a href="#"><i class="icofont-twitter"></i></a></li>
              <!-- <li><a href="#"><i class="icofont-pinterest"></i></a></li>
              <li><a href="#"><i class="icofont-youtube-play"></i></a></li>
              <li><a href="#"><i class="icofont-behance"></i></a></li>
              <li><a href="#"><i class="icofont-dribbble"></i></a></li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <!-- <div class="item">
            <h5>Recent Posts</h5>
            <ul>
              <li><a href="#">Contrary to popular belief</a></li>
              <li><a href="#">Lorem Ipsum is not simply</a></li>
              <li><a href="#">It has roots in a piece of </a></li>
              <li><a href="#">Latin literature from 45 BC</a></li>
              <li><a href="#">Making it over 2000 years old</a></li>
              <li><a href="#">There are many variations of</a></li>
            </ul>
          </div> -->
        </div>
        <div class="col-lg-4 col-md-6"></div>
        <div class="col-lg-2 col-md-6">
          <div class="item">
            <h5>More About</h5>
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="about">About</a></li>
              <li><a href="services">Services</a></li>
              <!-- <li><a href="#">Portfolio</a></li>
              <li><a href="#">Blog</a></li> -->
              <li><a href="contact">Contact</a></li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6">
          <div class="item">
            <h5>Subscribe us</h5>
            <form class="subscribe" action="#" method="post">
              <input type="text" name="subscribe" id="subscribe"  placeholder="Subscribe your mail id">
              <input type="submit" class="subscribe-btn" value="Subscribe">
            </form>
          </div>
          <div class="item"><img src="assets/images/payment.png" class="img-fluid" alt=""> <p>© 2020 All Rights Reserved by Nilanjan Banerjee</p></div>
          
        </div> -->
      </div>
    </div>
  </div>
</footer>